/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  won: 0,
  coins: 0,
  type: "",
  status: "",
  timeLeft: 0,
  showConfetti: false,
  showRandom: false,
  yokoMolo: "",
  // startAgain: false,
};

const slice = createSlice({
  name: "jackpot",
  initialState,
  reducers: {
    setCoins: (state, action) => {
      state.coins = action.payload;
    },
    setWon: (state, action) => {
      state.status = "won";
      state.won = action.payload.coins;
      state.type = action.payload.type;
    },
    resetJackpotTriger: (state) => {
      if (!state.won) state.status = "lose";

      // state.coins = 0;
    },
    setYokoMolo: (state, action) => {
      state.yokoMolo = action.payload;
    },

    setTimerDuration(state, action) {
      if (state.timeLeft > 0) return;

      const duration = action.payload;

      if (duration > 0 && duration <= 60) state.timeLeft = duration;
    },
    resetTimer(state) {
      state.won = 0;
      state.status = "";
      state.timeLeft = 60;
    },
    resetMes(state) {
      state.won = 0;
      state.status = "";
    },
    decrementTimer(state) {
      state.timeLeft -= 1;

      if (state.timeLeft === 0 && state.status) {
        state.won = 0;
        state.status = "";
      }
    },
    triggerConfetti(state) {
      state.showConfetti = true;
    },
    hideConfetti(state) {
      state.showConfetti = false;
    },
    hideRandom(state, action) {
      state.showRandom = action.payload;
    },
  },
});

const jackpotActions = slice.actions;

export { jackpotActions };
export default slice.reducer;

import React, { useEffect, useRef, useState } from "react";
import "../../assets/Confetti.css";

import gold0 from "../../assets/images/coins/Gold0-min.png";
import gold1 from "../../assets/images/coins/Gold1-min.png";
import gold2 from "../../assets/images/coins/Gold2-min.png";
import bronze0 from "../../assets/images/coins/bronzess-min.png";
import bronze1 from "../../assets/images/coins/bronzess2-min.png";
import bronze2 from "../../assets/images/coins/bronzess3-min.png";
import silver0 from "../../assets/images/coins/Silver0-min.PNG";
import silver1 from "../../assets/images/coins/Silver1-min.PNG";
import silver3 from "../../assets/images/coins/Silver3-min.PNG";
import { useDispatch } from "react-redux";
import { jackpotActions } from "../../store/ui/jackpot";
import Animation777 from "./Animation777";
import AnimationSkull from "./AnimationSkull";
import AnimationCherries from "./AnimationCherries";

const Confetti = ({ amount, type }) => {
  const [confettiCount, setConfettiCount] = useState(90);
  const confettiArray = Array.from({ length: confettiCount }, (_, i) => i);
  const [showLottie, setShowLottie] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const [showCoin, setShowCoin] = useState(false);
  const dispatch = useDispatch();

  const imagesgold = [gold0, gold1, gold2];
  const imagesbronze = [bronze0, bronze1, bronze2];
  const imagessilver = [silver0, silver1, silver3];

  const handleCloseMsg = () => {
    setShowMsg(false);
    dispatch(jackpotActions.hideConfetti());
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 520) {
        setConfettiCount(25);
      } else if (window.innerWidth <= 768) {
        setConfettiCount(40);
      } else if (window.innerWidth <= 1024) {
        setConfettiCount(60);
      } else {
        setConfettiCount(80);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLottie(false);
      setShowMsg(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCoin(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="confetti-container">
      {showCoin && (
        <>
          {confettiArray.map((_, index) => {
            const rotationX = Math.random() * 360;
            const rotationY = Math.random() * 360;
            const rotationZ = Math.random() * 360;

            const image =
              type === "MAIN"
                ? imagesgold[Math.floor(Math.random() * imagesgold.length)]
                : type === "500"
                  ? imagessilver[Math.floor(Math.random() * imagessilver.length)]
                  : imagesbronze[Math.floor(Math.random() * imagesbronze.length)];

            return (
              <div
                key={index}
                className="confetti"
                style={{
                  left: `${Math.random() * 100}%`,
                  animationDuration: `${Math.random() * 3 + 2}s`,
                  animationDelay: `${Math.random() * 3}s`,
                  transform: `rotateZ(${rotationX}deg)`,
                  backgroundImage: `url(${image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  width: "100px",
                  height: "100px",
                }}
              ></div>
            );
          })}
        </>
      )}

      {showLottie && (
        <div className="confetti-lottie-container">
          {type === "MAIN" ? (
            <Animation777 />
          ) : type === "500" ? (
            <AnimationSkull />
          ) : (
            <AnimationCherries />
          )}
        </div>
      )}
      {showMsg && (
        <div className="text-container-popup pulsing-popup">
          <h1 className="f16 yellow_text">Congratulations!</h1>
          <button className="close-popup-btn" onClick={handleCloseMsg}>
            X
          </button>{" "}
          <p>
            You have been awarded a staggering{" "}
            <span className="yellow_bold yellow_text">{amount?.toFixed(0)}</span> Coins in our
            recent jackpot drawing! Your luck has truly paid off, and we can't wait for you to enjoy
            your exciting winnings!
          </p>
        </div>
      )}
    </div>
  );
};

export default Confetti;

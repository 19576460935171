import { useEffect, useRef } from "react";
import lottie from "lottie-web";

import animationData from "../animation/Skull.json";

const AnimationSkull = () => {
  // ✅ Change function name to uppercase
  const container = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: container.current,
      renderer: "canvas", // Set renderer here
      loop: false,
      autoplay: true,
      animationData: animationData,
    });

    return () => anim.destroy(); // Cleanup on unmount
  }, []);

  return <div ref={container} className="newAnimation" />;
};

export default AnimationSkull;
// import { useEffect, useRef, useState } from "react";
// import lottie from "lottie-web";

// const AnimationSkull = () => {
//   const container = useRef(null);
//   const animationUrl =
//     "https://lionspride-dev.s3.us-east-2.amazonaws.com/lottie-animations/Skull.json"; // Replace with actual URL

//   useEffect(() => {
//     if (!container.current) return;

//     let anim;

//     const loadAnimation = async () => {
//       try {
//         const response = await fetch(animationUrl);
//         if (!response.ok) throw new Error("Network response was not ok");

//         const animationData = await response.json();

//         anim = lottie.loadAnimation({
//           container: container.current,
//           renderer: "svg", // Try switching to "svg"
//           loop: false,
//           autoplay: true,
//           animationData,
//         });
//       } catch (error) {
//         console.error("Failed to load animation:", error);
//       }
//     };

//     loadAnimation();

//     return () => {
//       if (anim) {
//         anim.destroy();
//       }
//     };
//   }, []);

//   return <div ref={container} className="newAnimation" style={{ width: "100%", height: "100%" }} />;
// };

// export default AnimationSkull;

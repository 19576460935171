// export const baseUri = "https://d438-144-48-130-73.ngrok-free.app";
// export const baseUri = "http://lionsdeneng-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";

// Staging server backend
// export const env = "sandbox";
// export const baseUri =
//   "http://lionsdengames-staging-env.eba-dus3uewu.us-east-2.elasticbeanstalk.com";

// export const baseUri = "http://192.168.0.181:8000";
// Production server backend
export const baseUri = "https://www.lionsprideengineering.com";
export const env = "prod";

export const theme = "no";
export const themeColorlight = theme === "christmas" ? "#ff3c3c" : "#ffd951";

export const baseUrl = `${baseUri}/api/`;

export const apiPath = baseUrl;
